import './country-image-detail.scss';
import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { ImageDetailLinkComp } from '@shared/components/image-detail-block/image-detail-block';
import ImageDetailBlockLayout from '@shared/components/image-detail-block/image-detail-block-layout';
import CardTip from '@shared/components/card-tip/card-tip';
import WorldFactBookDark from '@shared/svgs/glyphs/wfb-dark';
import LaunchpadContent from '@src/components/launchpad-content/launchpad-content';

const CountryImageDetail = ({ title, image, description }) => {
  const imageData = getImage(image.childImageSharp.gatsbyImageData);
  return (
    <ImageDetailBlockLayout
      titleComp={(
        <p className="label mb0 image-detail-block-header-title">{title}</p>
      )}
      linkComp={image && (
        <ImageDetailLinkComp {...image} />
      )}
      imageComp={image && (
        <GatsbyImage
          image={imageData}
          alt={image.altText}
          className="image-detail-block-image wfb-image-detail__wrapper"
          imgStyle={{
            objectFit: "none",
            objectPosition: "50% top",
          }}
        />
      )}
      subChildren={(
        <div className='col-md-6 col-sm-12'>
          <CardTip
            title='Usage'
            link={{ uri: '/about/copyright-and-contributors/', title: 'View Copyright Notice' }}
            icon={<WorldFactBookDark />}
          >
            <p>Factbook images and photos — obtained from a variety of sources — are in the public domain and are copyright free.</p>
          </CardTip>
        </div>
      )}
    >
      {description && <LaunchpadContent content={description} />}
    </ImageDetailBlockLayout>
  );
};

export default CountryImageDetail;
