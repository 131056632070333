import React, { useMemo } from 'react';
import NavArticleBody from '@shared/components/article/nav-article-body/nav-article-body';
import Image from '@shared/components/image';
import { cleanForSlug } from '@shared/utils/clean-slug';
import LaunchpadContent from '@src/components/launchpad-content/launchpad-content';
import ImageDownload from '@src/components/image-download/image-download';

const AppendixArticle = ({ description, entries }) => {
  const [menu, mappedEntries] = useMemo(() => {
    const mapped = entries.reduce((result, item) => {
      const category = item.fields.find((field) => field.attribute === 'category').value;
      if (!result[category]) {
        result[category] = [];
      }
      result[category].push({
        ...item,
        fields: {
          category: category,
          title: item.fields.find((field) => field.attribute === 'title').value,
          value: item.fields.find((field) => field.attribute === 'text').value,
        }
      });
      return result;
    }, {});
    return [Object.keys(mapped).map((item) => ({ label: item, slug: cleanForSlug(item) })), mapped];
  }, [entries]);

  return (
    <>
      <NavArticleBody>
        <LaunchpadContent content={description} />
      </NavArticleBody>
      <NavArticleBody menu={menu}>
        {menu.map((item) => (
          <div key={item.slug} id={item.slug}>
            <h2>{item.label}</h2>
            {mappedEntries[item.label]?.map((mappedItem) => (
              <div className='pb30'>
                <h3>{mappedItem.fields.title}</h3>
                <LaunchpadContent content={mappedItem.fields.value} />
                {mappedItem.media?.map((media, index) => (
                  <div className='reference-image'>
                    <ImageDownload {...media} key={index} />
                  </div>
                ))}
              </div>
            ))}
          </div>
        ))}
      </NavArticleBody>
    </>
  );
};

export default AppendixArticle;
