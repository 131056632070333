import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import FieldCategoryArticle, { CategoryBuilder } from '@src/components/field-article';
import Link from '@shared/components/link/link';
import { cleanForSlug } from '@shared/utils/clean-slug';
import { useOrderFieldLabels } from '@src/hooks/use-order-categories';

const CountryProfileGuideIndex = () => {
  const fieldLabels = useStaticQuery(graphql`
    query CountryProfileGuideQuery {
      fieldLabels: allLaunchpadFieldLabel(filter: {toggleType: {ne: "secondary"}}) {
        nodes {
          name
          databaseId
          subfieldLabels {
            name
            countryProfile
          }
        }
      }
    }
  `).fieldLabels.nodes;
  const orderedFields = useOrderFieldLabels(fieldLabels);

  return (
    <FieldCategoryArticle categories={orderedFields}>
      <CategoryBuilder FieldComp={FieldLabelDisplay} />
    </FieldCategoryArticle>
  );
};

const FieldLabelDisplay = ({ name, subfieldLabels }) => {
  const filteredLabels = subfieldLabels?.filter((item) => item.countryProfile);
  return (
    <div className='mb30'>
      <Link className='link-button bold' to={`/field/${cleanForSlug(name)}/`}>{name}</Link>
      {filteredLabels.length > 1 && (
        <div className='label'>
          <lu className='list-no-margin'>
            {filteredLabels.map((subfield) => (
              <li>{subfield.name}</li>
            ))}
          </lu>
        </div>
      )}
    </div>
  )
};

export default CountryProfileGuideIndex;
