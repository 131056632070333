import './image-download.scss'
import React from 'react';
import Image from '@shared/components/image';
import Link from '@shared/components/link/link';
import ImageIcon from '@shared/svgs/icons/image';

const ImageDownload = ({ altText, localFile }) => {
  return (
    <div class='image-download-container'>
      {altText && (
        <div className="image-download_alt-text">{altText}</div>
      )}
      {localFile && (
        <div class="image-download_image">
          <Link to={localFile.publicURL} class="image-download_download" download>
            <ImageIcon className="icon" />
          </Link>
          <Image localFile={localFile} />
        </div>
      )}
    </div>
  );
};

export default ImageDownload;
