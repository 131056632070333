import React from 'react';
import { graphql } from 'gatsby';
import LaunchpadLayout from '@src/components/launchpad-layout';
import HeroSplash from '@shared/components/hero-area/hero-splash/hero-splash';
import { useGlobalFooter } from '@shared/hooks/use-global-footer';
import { WFB_TEMPLATE } from '@src/utils/constants';
import LightboxIndex from '@src/components/index/lightbox-index';
import { cleanForSlug } from '@shared/utils/clean-slug';
import Seo from '@shared/components/layout/seo';

const CountryImagesTemplate = ({ data, location }) => {
  const { country, header } = data;
  const parentPage = { title: country.name, uri: `/countries/${cleanForSlug(country.name)}` };

  return (
    <LaunchpadLayout
      location={location}
      title={`${country.name} Photos - The World Factbook`}
    >
      <HeroSplash
        title={'Photos'}
        threadType='resources-threads'
        parentPage={parentPage}
      />
      <LightboxIndex images={country.images} />
    </LaunchpadLayout>
  );
};

export const countryQuery = graphql`
  query($id: String!) {
    country: launchpadPlace(id: {eq: $id}) {
      name
      images {
        category
        altText: alt
        caption
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData(height: 600, quality: 100, formats: [AUTO])
          }
        }
      }
    }
  }
`;

export const Head = ({ data, base }) => {
  const page = Object.values(data).find((item) => item !== null);
  return ( <Seo metaData={page.seo} base={base} title={`${data.country?.name} Images - The World Factbook`} /> );
}

export default CountryImagesTemplate;
