import React from 'react';
import { graphql } from 'gatsby';
import LaunchpadLayout from '@src/components/launchpad-layout';
import HeroSplash from '@shared/components/hero-area/hero-splash/hero-splash';
import ArticleBodyLayout from '@shared/components/article/article-body/article-body-layout';
import Image from '@shared/components/image';
import { useGlobalFooter } from '@shared/hooks/use-global-footer';
import LaunchpadContent from '@src/components/launchpad-content/launchpad-content';
import { WFB_TEMPLATE } from '@src/utils/constants';
import Link from '@shared/components/link/link';
import { cleanForSlug } from '@shared/utils/clean-slug';
import Seo from '@shared/components/layout/seo';

const TravelFactsTemplate = ({ data }) => {
  const { country, travelFact, header } = data;
  const parentPage = { title: travelFact.placeName, uri: `/countries/${cleanForSlug(travelFact.placeName)}` };

  return (
    <LaunchpadLayout
      title={travelFact.placeName}
    >
      <HeroSplash
        title='Travel Facts'
        threadType='resources-threads'
        parentPage={parentPage}
      />
      <ArticleBodyLayout contentClassName='col-lg-9 pb30 pt0'>
        <div className="header-subsection-print-button d-flex">
        {!travelFact.pdf && (
          <button type="button" onClick={() => window.print()} title="Select 'Save to PDF' as the Destination in the Print pop-up.">PDF</button>
        )}
        {travelFact.pdf && (
          <Link to={travelFact.pdf.publicURL} className='button button-default bold mt-3' download='true'>PDF</Link>
        )}
        </div>
        {travelFact.values.map((fact, index) => {
          if (fact.value) return (
            <span key={index}>
              <h3 className="mt30">{fact.label}</h3>
              <p>
                <LaunchpadContent content={fact.value} />
              </p>
            </span>
          )
        })}
        {travelFact.footer && (
          <span>
            <hr />
            <p>
              <LaunchpadContent content={travelFact.footer} />
            </p>
          </span>
        )}
        {country.qrCode?.childImageSharp && (
          <p>
            <Image className='wfb-image' localFile={country.qrCode} />
          </p>
        )}
      </ArticleBodyLayout>
    </LaunchpadLayout>
  );
};

export default TravelFactsTemplate;

export const Head = ({ data, base }) => {
  const page = Object.values(data).find((item) => item !== null);
  return ( <Seo metaData={page.seo} base={base} title={`${data.travelFact.placeName} Travel Facts - The World Factbook`} /> );
}

export const travelFactsQuery = graphql`
  query($code: String!) {
    country: launchpadPlace(code: {eq: $code}) {
      qrCode {
        childImageSharp {
          gatsbyImageData(
            height: 600
            quality: 100
            formats: [AUTO]
          )
        }
      }
    }
    travelFact: launchpadTravelFact(placeCode: {eq: $code}) {
      placeName
      values {
        attribute
        label
        value
      }
      footer
      pdf {
        publicURL
      }
    }
    header: wordpressWpMenu(slug: {eq: "global-header"}) {
      ...Header
    }
  }
`;
