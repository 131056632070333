import React from 'react'
import Parse, { domToReact } from 'html-react-parser';
import Link from '@shared/components/link/link';
import { useFileSource } from '@src/hooks/use-file-source';

export default function LaunchpadContent({ content }) {
  const fileSource = useFileSource;

  return (content && Parse(content, {
    replace: (domNode) => {
      if (domNode.type !== 'tag')
        return;

      if (domNode.name === 'a') {
        if (domNode.attribs.href?.substr(0, 4) !== 'http' && domNode.attribs.href?.substr(0, 1) !== '/') {
          const publicUrl = fileSource(domNode.attribs.href);
          if (publicUrl) {
            return <Link to={publicUrl} sameTab={false} download >{domToReact(domNode.children)}</Link>;
          }
        }


        return <Link to={domNode.attribs.href} {...domNode.attribs}>{domToReact(domNode.children)}</Link>;
      }
      // if this is an image tag do some content conversion
      else if (domNode.name === 'img') {
        if (domNode.attribs.src && domNode.attribs.src !== 'undefined') {
          const publicUrl = fileSource(domNode.attribs.src?.replace('/uploads/sites/2/', ''));
          if (publicUrl) {
            domNode.attribs.src = publicUrl;
          }
        }
        return domNode;
      }
    }
  }));
}
