import React, { useState, useMemo } from 'react';
import { graphql } from 'gatsby';
import { groupBy } from 'lodash';
import Layout from '@shared/components/layout/layout';
import FadeInSection from '@shared/components/utils/fade-in-section';
import HeroSplash from '@shared/components/hero-area/hero-splash/hero-splash';
import { WFB_TEMPLATE } from '@src/utils/constants';
import { useGlobalFooter } from '@shared/hooks/use-global-footer';
import SpecialProductIndex from '@src/components/index/special-product-index';

const SpecialProductFiles = ({ data }) => {
  const { places, header } = data;
  const parentPage = { title: 'The World Factbook', uri: '/' };

  const cleanedPlaces = useMemo(() => {
    return places.nodes?.map((place) => {
      const groups = groupBy(place.special, (item) => item.category || "World Facts & Rankings")
      return {
        ...place,
        documents: groups,
        categories: Object.keys(groups),
      };
    })
  }, [places]);

  return (
    <Layout
      template={WFB_TEMPLATE}
      title='Special Product Files - The World Factbook'
      headerMenu={header?.menuItems?.nodes}
      footerMenu={useGlobalFooter()}
    >
      <FadeInSection hero={true}>
        <HeroSplash
          title={'All Country Special Product Files'}
          threadType='resources-threads'
          parentPage={parentPage}
        />
      </FadeInSection>
      <SpecialProductIndex places={cleanedPlaces} />
    </Layout>
  );
};

export default SpecialProductFiles;

export const pageQuery = graphql`
  query {
    places: allLaunchpadPlace(filter: {special: {elemMatch: {id: {ne: null}}}}) {
      nodes {
        name
        special {
          category
          label: alt
          localFile {
            publicURL
          }
        }
      }
    }
    header: wordpressWpMenu(slug: {eq: "global-header"}) {
      ...Header
    }
  }
`;
