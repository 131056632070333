import React, { useState, useMemo } from 'react';
import { IndexLayout, IndexContent } from '@shared/components/index/index-layout';
import AZFilter from '@shared/components/index/filter/az-filter';
import FilterMenu, { ResultLabel } from '@shared/components/index/filter/filter-menu';
import { UnmanagedPaginationControl } from '@shared/components/index/pagination-controls/pagination-controls';
import ArticleBody from '@shared/components/article/article-body/article-body';
import HTMLContent from '@shared/components/html-content';
import { useFilters } from '@shared/hooks/use-filters';
import ImageDownload from '@src/components/image-download/image-download';

const AppendixIndex = ({ description, entries }) => {
  const getValue = (item) => item.fields.title.replace(/[^a-zA-Z ]/g, "");
  const sortedData = useMemo(() => {
    return entries.map((entry) => ({
      ...entry,
      fields: {
        title: entry.fields.find((field) => field.attribute === 'title').value,
        value: entry.fields.find((field) => field.attribute === 'text').value,
      }
    })).sort((a, b) => getValue(a).localeCompare(getValue(b)));
  }, [entries]);
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters, applyFilters] = useFilters();

  const { azFilter } = filters;
  const results = applyFilters(sortedData);

  return (
    <>
      {description && (
        <ArticleBody padding={false}>
          <HTMLContent content={description} />
        </ArticleBody>
      )}
      <IndexLayout>
        <FilterMenu sticky={true}>
          <ResultLabel
            size={results.length}
            clearAction={() => {
              setCurrentPage(1);
              setFilters({ azFilter: null });
            }}
          />
          <AZFilter
            value={azFilter}
            onFilterChange={(value) => {
              setCurrentPage(1);
              setFilters({ azFilter: value });
            }}
            items={sortedData}
            getItemValue={getValue}
          />
        </FilterMenu>
        <IndexContent>
          <UnmanagedPaginationControl
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            items={results}
            renderCallback={(items) => items.map((item, index) => (
              <div className='col-lg-9' key={index}>
                <div className="border pb30">
                  <div className="pb30">
                    <h2>{item.fields.title}</h2>
                    <div className="wysiwyg-wrapper" key={index}>
                      <HTMLContent content={item.fields.value} />
                    </div>
                    {item.media?.map((image) => {
                      if (!image) return null;
                      return <ImageDownload {...image} />
                    })}
                  </div>
                </div>
              </div>
            ))}
          />
        </IndexContent>
      </IndexLayout>
    </>
  );
};

export default AppendixIndex;
