import React, { useMemo } from 'react';
import { graphql } from 'gatsby';
import LaunchpadLayout from '@src/components/launchpad-layout';
import HeroSplash from '@shared/components/hero-area/hero-splash/hero-splash';
import ThreeColumnLinks from '@src/components/three-column-links/three-column-links';
import { cleanForSlug } from '@shared/utils/clean-slug';
import Seo from '@shared/components/layout/seo';

const RegionTemplate = ({ data, pageContext }) => {
  const { places, header } = data
  const parentPage = { title: 'The World Factbook', uri: '/' };

  const placeLinks = useMemo(() => {
    return places.nodes.map((place) => ({title: place.name, uri: `/countries/${cleanForSlug(place.redirect?.name ?? place.name)}/`}))
  }, []);

  return (
    <LaunchpadLayout
      title={pageContext.region}
    >
      <HeroSplash
        title={pageContext.region}
        threadType='resources-threads'
        parentPage={parentPage}
      />
      <ThreeColumnLinks columnLinks={placeLinks}/>
    </LaunchpadLayout>
  );
};

export default RegionTemplate;

export const Head = ({ pageContext, base }) => {
  const page = Object.values(pageContext).find((item) => item !== null);
  return ( <Seo metaData={pageContext.seo} base={base} title={`${pageContext.region} - The World Factbook`} /> );
}

export const regionQuery = graphql`
  query($region: String!) {
    places: allLaunchpadPlace(filter: {region: {eq: $region}}, sort: {fields: name}) {
      nodes {
        name
        redirect {
          name
        }
      }
    }
  }
`;
