import { graphql, useStaticQuery } from 'gatsby';

export const useFileSource = (name) => {
  const allFiles = useStaticQuery(graphql`
    query AllFiles {
      allFile {
        nodes {
          base
          publicURL
        }
      }
    }
  `).allFile.nodes;

  return allFiles.find((file) => file.base === name)?.publicURL ?? '';
};
