import './appendix-table.scss';
import React, { useMemo } from 'react';
import ArticleBodyLayout from '@shared/components/article/article-body/article-body-layout';
import ContentTable, { ArrayRow } from '@shared/components/content-table/content-table';
import Link from '@shared/components/link/link';
import { cleanForSlug } from '@shared/utils/clean-slug';
import LaunchpadContent from '@src/components/launchpad-content/launchpad-content';
import { useCountries } from '@src/hooks/use-countries';

const AppendixTable = ({ title, description, entries, RowComponent = ArrayRow }) => {
  const [header, content] = useMemo(() => {
    return [
      entries?.[0]?.fields?.map((item) => item.label),
      entries?.map((item) => item.fields.map((field) => field.value)),
    ];
  }, [entries]);

  return (
    <>
      <ArticleBodyLayout contentClassName='pb30' >
        <LaunchpadContent content={description} />
      </ArticleBodyLayout>
      {entries?.length > 0 && (
        <section className="content-section-mobile background-white">
          <div className="container">
            <div className="row">
              <div className="offset-lg-3 col-lg-9 col-md-12 col-sm-12 border-left-black pb90 content-area-content">
                <ContentTable
                  headerLabels={header}
                  tableContent={content}
                  RowComponent={RowComponent}
                  filename={title}
                  getDownloadContent={() => content.map((row) => {
                    const country = useCountries().find(country => country.name == row[0]);
                    const name = country ? country.name : row[0];
                    const genc = row[1]; const iso = row[2]; const stanag = row[3];
                    const internet = row[4]; const comment = row[5];
                    return { name, genc: genc, iso3166: iso, stanag: stanag, internet: internet, comment: comment };
                  })}
                />
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export const CountryLinkRow = ({ rowData }) => {
  const name = rowData.shift();
  const country = useCountries().find(country => country.name == name);
  const redirect = country ? (country.redirect?.name ?? country.name) : name;

  return (
    <>
      <td>
        <Link to={`/countries/${cleanForSlug(redirect)}/`} className="text-button content-table-link">
          {name}
        </Link>
      </td>
      {rowData.map((field, index) => (
        <td key={index}>{field}</td>
      ))}
    </>
  );
};

export default AppendixTable;
