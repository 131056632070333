import '@shared/components/teaser/teaser.scss';
import '@shared/components/teaser/teaser-content/teaser-content.scss';
import './wfb-teaser.scss';
import React from 'react';
import Image from '@shared/components/image';

const WFBTeaser = ({
  date,
  backgroundColor,
  inset,
  orientation,
  image,
  title,
  hideBorder,
  labelComp,
  children,
  hidePrint
}) => {
  const contentClass = backgroundColor === 'white' && !hideBorder ? ' border-left-black' : '';

  return (
    <>
      <section
        className={'background-grid-' + backgroundColor + (inset ? ' inset' : '')}
      >
        <div className='container teaser-container'>
          {date && (
            <div className='header-subsection-date-section d-flex'>
              <label className='header-subsection-date'>Page last updated: {date}</label>
            </div>
          )}
          <div className='row align-items-center justify-content-between'>
            <div className={`col-sm-12 col-md-5 ${orientation === 'left' ? 'order-last ' : ''}
                              ${hidePrint === true ? 'hide-print ' : ''}teaser-context pv120-90`}>
              <div className='content-card-teaser'>
                <h2 dangerouslySetInnerHTML={{ __html: title }} />
                <div className={'wfb-teaser-content'}>
                  {labelComp}
                  <div className={contentClass}>
                    {children}
                  </div>
                </div>
              </div>
            </div>
            <div className='col-sm-12 col-md-5 teaser-image pv120-90'  >
              <Image image={image} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WFBTeaser;
