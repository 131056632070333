import React from 'react';
import { useEffect, useState } from 'react';
import PageBuilder from '@shared/components/page-builder/page-builder';
import Teaser from '@src/components/teaser/wfb-teaser';
import ButtonLink from '@shared/components/utils/button-link';
import { useTodaysFact } from '@src/hooks/use-daily-fact';

const DailyFact = () => {
  const todaysFact = useTodaysFact();

  if (!todaysFact?.blocks?.[0]) return <section />;

  const [attributes, setAttributes] = useState(null);
  const [innerBlocks, setInnerBlocks] = useState(null);

  useEffect(() => {
    setInnerBlocks(todaysFact.blocks[0].innerBlocks)
    setAttributes(todaysFact.blocks[0].attributes)
  }, []);


  return (
    <Teaser
      {...attributes}
      title='Fact of the Day'
      backgroundColor='white'
      labelComp={<div className='label daily-fact__date pv15 pl15'>{todaysFact.visibleDate}</div>}
    >
      <h4 className={innerBlocks?.[innerBlocks?.length]?.name !== 'condor/button' ? 'archive' : ''}>
        {todaysFact.title}
      </h4>
      {innerBlocks && (
        <PageBuilder blocks={innerBlocks} />
      )}
      {innerBlocks?.[innerBlocks?.length]?.name !== 'condor/button' && (
        <ButtonLink
          id={'/daily-facts-archive/'}
          text={'VIEW 30-DAY ARCHIVE'}
          title={'View Daily Facts 30-Day Archive'}
          externalLink={false}
        />
      )}
    </Teaser >
  );
};

export default DailyFact;
