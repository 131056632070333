import React from 'react';
import { graphql } from 'gatsby';
import Layout from '@shared/components/layout/layout';
import Seo from '@shared/components/layout/seo';
import PageBuilder from '@shared/components/page-builder/page-builder';
import { useBlockFlatten } from '@shared/hooks/use-block-flatten'
import { useGlobalFooter } from '@shared/hooks/use-global-footer';
import { WFB_TEMPLATE } from '@src/utils/constants';
import { useBlockComponent } from '@components/site-component';

//declare meta data variables
const PageTemplate = ({ data, pageContext }) => {
  const page = Object.values(data).find((item) => item !== null);
  const parentPage = page.parentDatabaseId !== null ?
    page.parentPage :
    pageContext.parentPage;

  return (
    <Layout
      template={WFB_TEMPLATE}
      title={page.title}
      metaData={page.seo}
      headerMenu={data.header?.menuItems.nodes}
      footerMenu={useGlobalFooter()}
    >
      <PageBuilder
        blocks={useBlockFlatten(page.blocks)}
        getSiteComponent={useBlockComponent}
        pageContext={{
          ...pageContext,
          parentPage,
          baseUri: page.uri,
          tags: page?.tags,
        }}
      />
    </Layout>
  );
};

export const Head = ({ data, base }) => {
  const page = Object.values(data).find((item) => item !== null);
  return ( <Seo metaData={page.seo} base={base} title={page.title} /> );
}
export default PageTemplate;

export const pageQuery = graphql`
  query($id: String!) {
    wordpressWpPage(id: { eq: $id }) {
      seo {
        ...SEOFields
      }
      title
      ...ParentFields
      ...WFBBlockContentFields
    }
    wordpressWpTtMap(id: { eq: $id }) {
      seo {
        ...SEOFields
      }
      title
      ...ParentFields
      ...WFBBlockContentFields
    }
    wordpressWpTtAbout(id: { eq: $id }) {
      seo {
        ...SEOFields
      }
      title
      ...ParentFields
      ...WFBBlockContentFields
    }
    wordpressWpTtEdition(id: { eq: $id }) {
      seo {
        ...SEOFields
      }
      title
      ...ParentFields
      ...WFBBlockContentFields
    }
    wordpressWpTtReference(id: { eq: $id }) {
      seo {
        ...SEOFields
      }
      title
      ...ParentFields
      ...WFBBlockContentFields
    }
    header: wordpressWpMenu(slug: {eq: "global-header"}) {
      ...Header
    }
  }
`;
