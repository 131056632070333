import '@reach/dialog/styles.css';
import React, { useState, createContext, useContext } from 'react';
import Link from '@shared/components/link/link';
import HTMLContent from '@shared/components/html-content';
import ImageIcon from '@shared/svgs/icons/image';
import { decode } from 'html-entities';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Lightbox from 'yet-another-react-lightbox';
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails';
import { useLightboxState } from 'yet-another-react-lightbox/core';
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/captions.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';
import './media-lightbox.scss';
import './card-media.scss';

export const MediaLightboxContext = createContext(null);

export const MediaLightbox = ({ images, open, onClose, imageIndex = 0 }) => {
  const noSwipe = (e) => e.stopPropagation();
  const captionCopy = (e) => {
    var sel = window.getSelection().toString();
    navigator.clipboard.writeText(sel);
    e.preventDefault();
  }

  const renderContainer = (slide, children) => {
    return (
      <>
        {children}
        <div className='yarl__slide_captions_container yarl__slide_description_container' onPointerDown={noSwipe}>
          <div className="yarl__slide_description" onCopy={captionCopy}>
            <HTMLContent content={slide.caption} />
          </div>
        </div>
      </>
    );
  };

  const renderSlide = (slide) => {
    const { state: { currentIndex } } = useLightboxState();

    if (!slide) return null;

    const imageData = getImage(slide.gatsbySrc);
    const currentSlide = slide.index === currentIndex;
    return (
      <div aria-hidden={!currentSlide}>
        {currentSlide && (
          <Link to={slide.publicURL} className='download' aria-label='Download Image' download>
            <ImageIcon className='icon' />
          </Link>
        )}
        <GatsbyImage
          image={imageData}
          alt={slide.caption}
        />
      </div>
    );
  };

  const renderButtonNext = () => useLightboxState().state.currentIndex === images.length - 1 ? null : (
    <div className='card-media__controls-wrapper'>
      <span role='button' className='pagination__arrow-right photo-nav' aria-label='Next'>
        <svg className='icon icon-red icon-arrow-wide' aria-label='Forwards' viewBox='0 0 30 30'>
          <title>Next Page</title>
          <polygon fill='#161618' fillRule='nonzero' points='19.561 14.854 11.707 22.708 11 22.001 18.146 14.855 11 7.707 11.707 7' />
        </svg>
      </span>
    </div>
  );
  const renderButtonPrev = () => useLightboxState().state.currentIndex === 0 ? null : (
    <div className='card-media__controls-wrapper'>
      <span role='button' className='pagination__arrow-left photo-nav' aria-label='Previous'>
        <svg className='icon icon-red icon-arrow-wide' aria-label='Backwards' viewBox='0 0 30 30'>
          <title>Previous Page</title>
          <polygon fill='#161618' fillRule='nonzero' points='12.414 14.853 19.56 22 18.853 22.707 11 14.853 18.854 7 19.561 7.707' />
        </svg>
      </span>
    </div>
  );

  return (
    <Lightbox
      open={images && open}
      index={imageIndex}
      close={onClose}
      slides={images.map((image, index) => ({
        // src only required and used by default like in thumbnails
        // others used in custom renders
        src: image.localFile.childImageSharp.gatsbyImageData.images.fallback.src,
        gatsbySrc: image.localFile.childImageSharp.gatsbyImageData,
        publicURL: image.localFile.publicURL,
        caption: decode(image.caption),
        index: index
      }))}
      animation={{
        fade: 300, swipe: 200
      }}
      styles={{
        container: {
          '--yarl__slide_description_max_lines': '-1',
          '--yarl__navigation_button_padding': '0'
        }
      }}
      plugins={[Thumbnails]}
      thumbnails={{
        position: 'top'
      }}
      carousel={{ preload: 5, finite: true }}
      render={{
        slideContainer: renderContainer,
        slide: renderSlide,
        iconPrev: renderButtonPrev,
        iconNext: renderButtonNext
      }}
    />
  );
};

export const MediaButton = ({ className = '', index = 0, children }) => {
  const context = useContext(MediaLightboxContext);

  return (
    <div
      className={className}
      role='button'
      tabIndex='0'
      onClick={() => context?.setImageIndex?.(index)}
      onKeyDown={(event) => { if (event.key === 'Enter') event.currentTarget.click() }}
    >
      {children}
    </div>
  )
};

const MediaLightboxContainer = ({ images=[], children }) => {
  const [imageIndex, setImageIndex] = useState(-1);

  return (
    <>
      <MediaLightboxContext.Provider value={{ setImageIndex }}>
        {children}
      </MediaLightboxContext.Provider>
      <MediaLightbox
        images={images}
        imageIndex={imageIndex}
        open={imageIndex > -1}
        onClose={() => setImageIndex(-1)}
      />
    </>
  );
};

export default MediaLightboxContainer;
