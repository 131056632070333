import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import FieldCategoryArticle, { CategoryBuilder } from '@src/components/field-article';
import Link from '@shared/components/link/link';
import { cleanForSlug } from '@shared/utils/clean-slug';
import { useOrderFieldLabels } from '@src/hooks/use-order-categories';

const CountryComparisonGuideIndex = () => {
  const fieldLabels = useStaticQuery(graphql`
    query CountryComparisonGuideQuery {
      fieldLabels: allLaunchpadFieldLabel(filter: {rank: {eq: true}}) {
        nodes {
          name
          databaseId
        }
      }
    }
  `).fieldLabels.nodes;
  const orderedFields = useOrderFieldLabels(fieldLabels);

  return (
    <FieldCategoryArticle categories={orderedFields}>
      <CategoryBuilder FieldComp={FieldLabelDisplay} />
    </FieldCategoryArticle>
  );
};

const FieldLabelDisplay = ({ name }) => {
  return (
    <div className='mb30'>
      <Link className='link-button bold' to={`/field/${cleanForSlug(name)}/country-comparison/`}>{name}</Link>
    </div>
  )
};

export default CountryComparisonGuideIndex;
