import React, { useState } from 'react';
import { IndexLayout, IndexContent } from '@shared/components/index/index-layout';
import AZFilter from '@shared/components/index/filter/az-filter';
import CategoryFilter from '@shared/components/index/filter/category-filter';
import FilterMenu, { ResultLabel } from '@shared/components/index/filter/filter-menu';
import { UnmanagedPaginationControl } from '@shared/components/index/pagination-controls/pagination-controls';
// import Link from '@shared/components/link/link';
// import { CardGalleryLayout, CardLink } from '@shared/components/article/article-content/card-gallery/card-gallery-layout';
// import FileIcon from '@shared/svgs/icons/file';
import SpecialProductList from '@src/components/special-products/special-products';
import { useFilters } from '@shared/hooks/use-filters';
import { cleanForSlug } from '@shared/utils/clean-slug';

const SpecialProductIndex = ({ places }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters, applyFilters] = useFilters();

  const { azFilter, categoryFilter } = filters;
  const results = applyFilters(places);

  return (
    <IndexLayout>
      <FilterMenu sticky={true}>
        <ResultLabel
          size={results.length}
          clearAction={() => {
            setCurrentPage(1);
            setFilters({ azFilter: null, categoryFilter: null });
          }}
        />
        <CategoryFilter
          value={categoryFilter}
          onFilterChange={(value) => {
            setCurrentPage(1);
            setFilters({ categoryFilter: value });
          }}
          items={azFilter?.selection ? azFilter.lambda(places, azFilter.selection) : places}
          getItemValue={(item) => item.categories}
          multipleCategories
        />
        <AZFilter
          value={azFilter}
          onFilterChange={(value) => {
            setCurrentPage(1);
            setFilters({ azFilter: value });
          }}
          items={categoryFilter?.selection ? categoryFilter.lambda(places, categoryFilter.selection) : places}
          getItemValue={(item) => item.name}
        />
      </FilterMenu>
      <IndexContent>
        <UnmanagedPaginationControl
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          renderCallback={(items) => items?.map((item, index) => (
            <SpecialProductList
              key={index}
              title={item.name}
              uri={`/countries/${cleanForSlug(item.redirect?.name ?? item.name)}/`}
              documentGroups={item.documents}
              documentCategories={item.categories}
            />
          ))}
          items={results}
          pageSize={12}
        />
      </IndexContent>
    </IndexLayout>
  );
};

export default SpecialProductIndex;
