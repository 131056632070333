import './country-links.scss';
import React from 'react';
import CardLayout from '@shared/components/cards/card-layout';
import Image from '@shared/components/image';
import { CardGalleryLayout, CardLink } from '@shared/components/article/article-content/card-gallery/card-gallery-layout';
import FileIcon from '@shared/svgs/icons/file';
import ArrowRightIcon from '@shared/svgs/icons/arrow-right';
import WorldFactBookDark from '@shared/svgs/glyphs/wfb-dark';

const CountryLinks = ({ flag,
  map,
  locatorMap,
  summary,
  travelFacts,
  media,
  largeCard = false,
  flagTitle = 'Country Flag',
  mapTitle = 'Country Map',
  productTitle = 'Special Country Products',
}) => {
  const className = largeCard ? 'col-12' : 'col-md-6';
  return (
    <div className='wfb-country-link-display hide-print'>
      <CardLayout backgroundColor='white'>
        {flag?.localFile && (
          <CountryLink className={className} image={flag} title={flagTitle} links={[
            { to: './flag/', label: 'View Details' }
          ]} />
        )}
        {map?.localFile && (
          <CountryLink className={className} image={map} title={mapTitle} links={[
            { to: './map/', label: 'View Details' }
          ]} />
        )}
        {(summary || travelFacts || media?.length > 0) && (
          <CountryLink className={className} title={productTitle} links={[
            ...summary ? [{ to: './summaries/', label: 'Country Summary' }] : [],
            ...travelFacts ? [{ to: './travel-facts/', label: 'Travel Facts' }] : [],
            ...media?.length > 0 ? [
              ...media.map((item) => ({ ...item, icon: FileIcon, animated: false, download: true })),
              { to: '/special-product-files/', label: 'View All Files' },
            ] : [],
          ]} />
        )}
        {locatorMap?.localFile && (
          <CountryLink className={className} image={locatorMap} title={'Locator Map'} links={[
            { to: './locator-map/', label: 'View Details' }
          ]} />
        )}
      </CardLayout>
    </div>
  );
};

const CountryLink = ({ className, image, links, title }) => {
  return (
    <div className={className}>
      <CardGalleryLayout
        className='mb30'
        imageComp={(
          <>
            {image?.localFile && (
              <div className='wfb-card__image-container'>
                <div className='wfb-card__image'>
                  <Image className="card-gallery__image" image={image} />
                </div>
              </div>
            )}
            {!image?.localFile && (
              <WorldFactBookDark className="card-gallery__image" />
            )}
          </>
        )}
        linkComp={links.map((link) => (
          <CardLink key={link.to} IconComp={link.icon ?? ArrowRightIcon} animated={link.animated ?? true} to={link.to} download={link.download}>
            {link.label}
          </CardLink>
        ))}
      >
        <span className='mb0 p15 h5-style'>{title}</span>
      </CardGalleryLayout>
    </div>
  );
};

export default CountryLinks;
