import React from 'react';
import { graphql } from 'gatsby';
import LaunchpadLayout from '@src/components/launchpad-layout';
import HeroSplash from '@shared/components/hero-area/hero-splash/hero-splash';
import CountryImageDetail from '@src/components/country-image-detail/country-image-detail'
import { cleanForSlug } from '@shared/utils/clean-slug';
import Seo from '@shared/components/layout/seo';

const CountryMediaDetailTemplate = ({ location, data }) => {
  const { country, description } = data;
  const parentPage = { title: country.name, uri: `/countries/${cleanForSlug(country.name)}` };

  const image = country.flag ?? country.map ?? country.locatorMap;

  return (
    <LaunchpadLayout
      location={location}
      title={'Details - The World Factbook'}
    >
      <HeroSplash
        title='Details'
        threadType='resources-threads'
        parentPage={parentPage}
      />
      <CountryImageDetail
        title={country.name}
        image={image}
        description={description?.formatted}
      />
    </LaunchpadLayout>
  );
};

export const countryFlagQuery = graphql`
  fragment FileDetailsFields on File {
    prettySize
    extension
    childImageSharp {
      gatsbyImageData(layout: CONSTRAINED, width: 700, quality: 100)
    }
  }
  query(
    $id: String!,
    $placeCode: String!,
    $fieldLabelId: ID!,
    $flag: Boolean = false,
    $map: Boolean = false,
    $locationMap: Boolean = false
  ) {
    country: launchpadPlace(id: {eq: $id}) {
      name
      flag @include(if: $flag) {
        ...FileDetailsFields
        staticPublicURL
      }
      map @include(if: $map) {
        ...FileDetailsFields
        staticPublicURL
      }
      locatorMap @include(if: $locationMap) {
        ...FileDetailsFields
        staticPublicURL
      }
    }
    description: launchpadField(fieldLabelId: {eq: $fieldLabelId}, placeCode: {eq: $placeCode}) {
      formatted
    }
  }
`;

export const Head = ({ data, base }) => {
  const page = Object.values(data).find((item) => item !== null);
  return ( <Seo metaData={page.seo} base={base} title={`${data.country?.name} Details - The World Factbook`} /> );
}

export default CountryMediaDetailTemplate;
