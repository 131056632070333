import React from 'react';
import { graphql } from 'gatsby';
import LaunchpadLayout from '@src/components/launchpad-layout';
import HeroSplash from '@shared/components/hero-area/hero-splash/hero-splash';
import Link from '@shared/components/link/link';
import ButtonLink from '@shared/components/utils/button-link';
import { useGlobalFooter } from '@shared/hooks/use-global-footer';
import { cleanForSlug } from '@shared/utils/clean-slug';
import { randomNum } from '@shared/utils/functions';
import { WFB_TEMPLATE } from '@src/utils/constants';
import CountryLinks from '@src/components/country-links/country-links';
import FieldCategoryArticle, { CategoryBuilder, CategoryDisplay } from '@src/components/field-article';
import CardTip from '@shared/components/card-tip/card-tip';
import WorldFactBookDark from '@shared/svgs/glyphs/wfb-dark';
import WFBTeaser from '@src/components/teaser/wfb-teaser';
import { useOrderFields } from '@src/hooks/use-order-categories';
import Seo from '@shared/components/layout/seo';
import { cleanSEO } from '@src/utils/clean-seo';

const CountryTemplate = ({ location, data }) => {
  const { rep, country, fields, travelFact, header } = data;
  const cleanedSEO = cleanSEO(rep.seo, {
    '$title': country.name,
  });
  const orderedFields = useOrderFields(fields.nodes);
  const isCountry = country.region !== 'Oceans' && country.region !== '';
  const parentPage = isCountry ?
    { title: 'Explore All Countries', uri: '/countries/' } :
    { title: 'The World Factbook', uri: '/' };
  const randomImage = randomNum(country.images?.length);
  const specialDocuments = country.special?.map((item) => ({ to: item.localFile.publicURL, label: item.alt }));

  return (
    <LaunchpadLayout
      location={location}
      metaData={cleanedSEO}
    >
      <HeroSplash
        title={country.name}
        threadType='resources-threads'
        parentPage={parentPage}
      >
        {isCountry && <Link to={`/${cleanForSlug(country.region)}/`} className='button-font link-button bold'>{country.region}</Link>}
      </HeroSplash>
      <WFBTeaser
        hidePrint={true}
        backgroundColor='white'
        date={country.updated}
        image={country.images?.[randomImage]}
        title={`Photos of ${country.name}`}
        orientation='left'
      >
        {country.images?.[randomImage]?.localFile && (
          <ButtonLink
            id='./images/'
            text={`view ${country.images.length} photos`}
          />
        )}
        {!country.images?.[randomImage]?.localFile && (
          <p>There are no photos for {country.name}.</p>
        )}
      </WFBTeaser>
      <CountryLinks
        flag={{ localFile: country.flag }}
        map={{ localFile: country.map }}
        locatorMap={{ localFile: country.locatorMap }}
        summary={country.locations.find((item) => item === 'summary')}
        travelFacts={travelFact?.placeName}
        media={specialDocuments}
        largeCard={country.region === 'Oceans'}
        productTitle={isCountry ? 'Special Country Products' : 'Special Products'}
        mapTitle={country.region === '' ? 'Map of the World' : 'Country Map'}
      />
      <FieldCategoryArticle categories={orderedFields} useFieldLink useRank={country.rank}>
        <CategoryBuilder renderCategoryCallback={(category) => (
          <React.Fragment key={category.name}>
            <CategoryDisplay category={category} />
            {category.name === 'Introduction' && (
              <CardTip
                title='Tip'
                link={{ uri: '/references/definitions-and-notes/', title: 'Definitions and Notes' }}
                icon={<WorldFactBookDark />}
              >
                <p>Visit the Definitions and Notes page to view a description of each topic.</p>
              </CardTip>
            )}
          </React.Fragment>
        )}
        />
      </FieldCategoryArticle>
    </LaunchpadLayout>
  );
};

export const countryQuery = graphql`
  query($id: String!, $placeCode: String!) {
    country: launchpadPlace(id: {eq: $id}) {
      name
      region
      locations
      updated: placeUpdated(formatString: "MMMM DD, YYYY")
      rank
      flag {
        ...FileFields
      }
      map {
        ...FileFields
      }
      locatorMap {
        ...FileFields
      }
      images {
        ...AttachmentFields
      }
      special {
        alt
        localFile {
          publicURL
        }
      }
    }
    fields: allLaunchpadField(
      filter: {placeCode: {eq: $placeCode}, fieldLabel: {elemMatch: {locations: {eq: "place"}}}, formatted: {nin: ["", null]}}
    ) {
      nodes {
        name
        data: formatted
        subfields {
          label
          ranking
        }
        fieldLabel {
          rank
          databaseId
          toggleWithId
          toggleType
          subfieldLabels {
            databaseId
            rank
          }
        }
        media {
          type
          altText: alt
          caption
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(
                height: 600
                quality: 100
                formats: [AUTO]
              )
            }
          }
        }
      }
    }
    travelFact: launchpadTravelFact(placeCode: {eq: $placeCode}) {
      placeName
    }
    rep: wordpressWpCountry {
      seo {
        ...SEOFields
      }
    }
  }
`;

export const Head = ({ data, base }) => {
  const page = Object.values(data).find((item) => item !== null);
  return ( <Seo metaData={page.seo} base={base} title={`${data.country?.name} - The World Factbook`} /> );
}

export default CountryTemplate;
