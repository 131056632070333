import React, { useMemo } from 'react';
import { navigate } from 'gatsby';
import SearchInput from '@shared/components/search-input/search-input';
import { cleanForSlug } from '@shared/utils/clean-slug';
import { useCountries } from '@src/hooks/use-countries';

const CountrySearch = (props) => {
  const countries = useCountries();
  const valueList = useMemo(() => countries.map((item) => item.name), [countries]);

  return (
    <SearchInput
      {...props}
      valueList={valueList}
      onSelection={(value) => {
        const match = countries.find((item) => item.name.toLowerCase() === value.toLowerCase());
        if (match)
          navigate(`/countries/${cleanForSlug(match.redirect?.name ?? match.name)}`);
        return !!match;
      }}
    />
  );
};

export default CountrySearch;
