import React, { useMemo } from 'react';
import { graphql } from 'gatsby';
import LaunchpadLayout from '@src/components/launchpad-layout';
import HeroSplash from '@shared/components/hero-area/hero-splash/hero-splash';
import AppendixIndex from '@src/components/index/appendix-index';
import AppendixTable, { CountryLinkRow } from '@src/components/index/appendix-table';
import AppendixArticle from '@src/components/index/appendix-article';
import { cleanSEO } from '@src/utils/clean-seo';
import Seo from '@shared/components/layout/seo';

const AppendixTemplate = ({ data }) => {
  const parentPage = { title: 'References', uri: '/references/' };
  const { appendix, rep: { seo }, header } = data;
  const cleanedSEO = cleanSEO(seo, {
    '$title': appendix.name,
  });
  const key = appendix.code === 'D' ? 'D' : appendix.format;

  return (
    <LaunchpadLayout
      metaData={cleanedSEO}
    >
      <HeroSplash
        title={appendix.name}
        threadType='resources-threads'
        parentPage={parentPage}
      />
      {useAppendixIndex(appendix.name, appendix.description, appendix.text, appendix.entries)[key]}
    </LaunchpadLayout>
  );
};

const useAppendixIndex = (title, description, text, entries) => ({
  'D': <AppendixTable title={title} description={description} entries={entries} RowComponent={CountryLinkRow} />,
  'static': <AppendixTable title={title} description={text} />,
  'a-z': <AppendixIndex description={description} entries={entries} />,
  'tabular': <AppendixTable title={title} description={description} entries={entries} />,
  'categorized': <AppendixArticle description={description} entries={entries} />,
});

export default AppendixTemplate;

export const Head = ({ data, base }) => {
  const page = Object.values(data).find((item) => item !== null);
  return ( <Seo metaData={page.seo} base={base} title={`${data.appendix.name} - The World Factbook`} /> );
}

export const appendixQuery = graphql`
  query($id: String!, $pageId: String!) {
    appendix: launchpadAppendix(id: { eq: $id }) {
      name
      code
      format
      text
      description
      entries {
        fields {
          attribute
          label
          value
        }
        media {
          localFile {
            publicURL
          }
          ...AttachmentFields
        }
      }
    }
    rep: wordpressWpAppendix(id: { eq: $pageId }) {
      seo {
        ...SEOFields
      }
    }
  }
`;
