import { useStaticQuery, graphql } from 'gatsby';

export const useCountries = () => {
  return useStaticQuery(graphql`
    query CountryIndexQuery {
      countries: allLaunchpadPlace(
        sort: {fields: name}
        filter: {region: {ne: "Oceans"}}
      ) {
        nodes {
          name
          redirect {
            name
          }
        }
      }
    }
  `).countries.nodes;
}
