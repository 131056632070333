import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Layout from '@shared/components/layout/layout';
import { useGlobalFooter } from '@shared/hooks/use-global-footer';
import { WFB_TEMPLATE } from '@src/utils/constants';

const LaunchpadLayout = ({ location, children, ...props }) => {
  const header = useStaticQuery(graphql`
    query GlobalHeader {
      header: wordpressWpMenu(slug: {eq: "global-header"}) {
        ...Header
      }
    }
  `).header?.menuItems?.nodes;

  var base = location ? location.origin + location.pathname : null;
  if (!base?.endsWith('/')) base += '/';

  return (
    <Layout
      {...props}
      base={base}
      template={WFB_TEMPLATE}
      headerMenu={header}
      footerMenu={useGlobalFooter()}
    >
      {children}
    </Layout>
  )
};

export default LaunchpadLayout;
