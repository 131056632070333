import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { IndexLayout, IndexContent } from '@shared/components/index/index-layout';
import AZFilter from '@shared/components/index/filter/az-filter';
import FilterMenu, { ResultLabel } from '@shared/components/index/filter/filter-menu';
import Link from '@shared/components/link/link';
import { UnmanagedPaginationControl } from '@shared/components/index/pagination-controls/pagination-controls';
import { cleanForSlug } from '@shared/utils/clean-slug';
import { useFilters } from '@shared/hooks/use-filters';

const TravelFactIndex = () => {
  const facts = useStaticQuery(graphql`
    query TravelFactQuery {
      facts: allLaunchpadTravelFact(sort: {fields: placeName}) {
        nodes {
          placeName
        }
      }
    }
  `).facts.nodes;

  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters, applyFilters] = useFilters();
  const { azFilter } = filters;
  const results = applyFilters(facts);

  return (
    <IndexLayout>
        <FilterMenu sticky={true}>
          <ResultLabel
            size={results.length}
            clearAction={() => {
              setFilters({ azFilter: null });
              setCurrentPage(1);
            }}
          />
          <AZFilter
            value={azFilter}
            onFilterChange={(value) => {
              setFilters({ azFilter: value });
              setCurrentPage(1);
            }}
            items={facts}
            getItemValue={(item) => item.placeName}
          />
        </FilterMenu>
        <IndexContent>
          <UnmanagedPaginationControl
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            renderCallback={(items) => (
              <>
                {items?.map((item, index) => (
                  <div key={item.placeName} className='col-lg-9 pb10'>
                    <h2 className='mt30'>
                      <Link to={`/countries/${cleanForSlug(item.placeName)}/travel-facts`}>{item.placeName}</Link>
                    </h2>
                  </div>
                ))}
              </>
            )}
            items={results}
            pageSize={12}
          />
        </IndexContent>
      </IndexLayout>
  );
};

export default TravelFactIndex;
