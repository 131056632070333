import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { IndexLayout, IndexContent } from '@shared/components/index/index-layout';
import AZFilter from '@shared/components/index/filter/az-filter';
import FilterMenu, { ResultLabel } from '@shared/components/index/filter/filter-menu';
import Link from '@shared/components/link/link';
import { UnmanagedPaginationControl } from '@shared/components/index/pagination-controls/pagination-controls';
import { cleanForSlug } from '@shared/utils/clean-slug';
import { useFilters } from '@shared/hooks/use-filters';

const CountrySummaryIndex = () => {
  const places = useStaticQuery(graphql`
    query CountrySummaryQuery {
      places: allLaunchpadPlace(filter: {locations: {eq: "summary"}}, sort: {fields: name}) {
        nodes {
          name
        }
      }
    }
  `).places.nodes;

  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters, applyFilters] = useFilters();
  const { azFilter } = filters;
  const results = applyFilters(places);

  return (
    <IndexLayout>
        <FilterMenu sticky={true}>
          <ResultLabel
            size={results.length}
            clearAction={() => {
              setFilters({ azFilter: null });
              setCurrentPage(1);
            }}
          />
          <AZFilter
            value={azFilter}
            onFilterChange={(value) => {
              setFilters({ azFilter: value });
              setCurrentPage(1);
            }}
            items={places}
            getItemValue={(item) => item.name}
          />
        </FilterMenu>
        <IndexContent>
          <UnmanagedPaginationControl
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            renderCallback={(items) => (
              <>
                {items?.map((item, index) => (
                  <div key={index} className='col-lg-9 pb10'>
                    <h2 className='mt30'>
                      <Link to={`/countries/${cleanForSlug(item.name)}/summaries`}>{item.name}</Link>
                    </h2>
                  </div>
                ))}
              </>
            )}
            items={results}
            pageSize={12}
          />
        </IndexContent>
      </IndexLayout>
  );
};

export default CountrySummaryIndex;
