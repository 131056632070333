import React, { useMemo, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { IndexLayout, IndexContent } from '@shared/components/index/index-layout';
import AZFilter from '@shared/components/index/filter/az-filter';
import FilterMenu, { ResultLabel } from '@shared/components/index/filter/filter-menu';
import { UnmanagedPaginationControl } from '@shared/components/index/pagination-controls/pagination-controls';
import LaunchpadContent from '@src/components/launchpad-content/launchpad-content';
import Link from '@shared/components/link/link';
import { useFilters } from '@shared/hooks/use-filters';
import { cleanForSlug } from '@shared/utils/clean-slug';
import AlignLeftIcon from '@shared/svgs/icons/align-left';
import { useCreateURI } from '@src/hooks/use-create-uri';

const DefinitionsNotesIndex = () => {
  const data = useStaticQuery(graphql`
    query DefinitionsNotes {
      fieldDefinitions: allLaunchpadFieldLabel(filter: {toggleType: {ne: "secondary"}}) {
        nodes {
          name
          definition
        }
      }
      notes: allLaunchpadNote {
        nodes {
          values {
            attribute
            label
            value
          }
        }
      }
      appendix: allLaunchpadAppendix {
        nodes {
          name
          code
        }
      }
    }
  `);
  const generateURI = useCreateURI();

  const notesDefinitions = useMemo(() => {
    const { appendix, fieldDefinitions, notes } = data;

    const formattedNotes = notes.nodes.map((note) => ({
      name: note.values.find((field) => field.attribute === 'title').value,
      definition: note.values.find((field) => field.attribute === 'text').value,
      note: true,
    }));

    return [...fieldDefinitions.nodes, ...formattedNotes].sort((a, b) => a.name.localeCompare(b.name));
  }, [data]);

  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters, applyFilters] = useFilters();
  const { azFilter } = filters;
  const results = applyFilters(notesDefinitions);

  return (
    <IndexLayout>
      <FilterMenu sticky={true}>
        <ResultLabel
          size={results.length}
          clearAction={() => {
            setCurrentPage(1);
            setFilters({ azFilter: null })
          }}
        />
        <AZFilter
          value={azFilter}
          onFilterChange={(value) => {
            setCurrentPage(1);
            setFilters({ azFilter: value })
          }}
          items={notesDefinitions}
          getItemValue={(item) => item.name}
        />
      </FilterMenu>
      <IndexContent>
        <UnmanagedPaginationControl
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          renderCallback={(items) => items?.map((item, index) => (
            <div key={index} className='col-lg-9 pb30'>
              <h2 className='mt30'>{item.name}</h2>
              <div className='wysiwyg-wrapper'>
                <LaunchpadContent content={item.definition} />
              </div>
              {!item.note && (
                <Link
                  className="card-exposed__link pl0 mt45"
                  to={generateURI('field', cleanForSlug(item.name))}
                >
                  <span className="card-exposed__text link-button bold h5">View List</span>
                  <AlignLeftIcon className="icon" />
                </Link>
              )}
            </div>
          ))}
          items={results}
          pageSize={12}
        />
      </IndexContent>
    </IndexLayout>
  );
};

export default DefinitionsNotesIndex;
