import React from 'react';

const ResultLengthDropdown = ({ length, value, onChange }) => {
  return (
    <div className="col-lg-3 col-sm-12 label bold pull-right">
      Per Page:
      <select className="per-page" value={value} onChange={(event) => onChange(event.target.value)}>
        <option value="12">12</option>
        {length > 24 && (
          <option value="24">24</option>
        )}
        {length > 48 && (
          <option value="48">48</option>
        )}
        <option value="-1">All</option>
      </select>
    </div>
  );
};

export default ResultLengthDropdown;
