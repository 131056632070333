import './special-products.scss';
import React from 'react';
import Link from '@shared/components/link/link';
import { CardGalleryLayout, CardLink } from '@shared/components/article/article-content/card-gallery/card-gallery-layout';
import FileIcon from '@shared/svgs/icons/file';

const SpecialProductList = ({ title, uri, documentGroups, documentCategories }) => {
  return (
    <div className="col-lg-9">
      <div className="pb30 wfb-special-product-container">
        <h2><Link className="inline-link" to={uri}>{title}</Link></h2>
        {documentCategories?.map((key) => {
          if (documentGroups[key].length > 1) {
            return (
              <CardGalleryLayout
                className='mb30'
                linkComp={documentGroups[key].map((document) => (
                  <CardLink key={document.localFile.publicURL} IconComp={FileIcon} to={document.localFile.publicURL}>
                    {document.label}
                  </CardLink>
                ))}
              >
                <span className='mb0 p15 h5-style'>{key}</span>
              </CardGalleryLayout>
            );
          }
          else if (documentGroups[key].length === 1) {
            return (
              <SpecialProductSingle uri={documentGroups[key][0].localFile.publicURL}>
                {documentGroups[key][0].label}
              </SpecialProductSingle>
            );
          }
        })}
      </div>
    </div>
  )
};

export const SpecialProductSingle = ({ uri, children }) => {
  return (
    <Link to={uri} className="d-block wfb-special-product" download>
      <div className="card-gallery__section-text-block wfb-special-product_text-block">
        <div className='wfb-special-product_icon'>
          <FileIcon className={'card-gallery__icon-color'} />
        </div>
        <p className="wfb-special-product_section-text button-font-alt">
          {children}
        </p>
      </div>
    </Link>
  );
};

export default SpecialProductList;
