import './country-summary-images.scss';
import React from 'react';
import Link from '@shared/components/link/link';
import Image from '@shared/components/image';

const CountrySummaryImages = ({ summaryPdf, locatorMap, flag, map, populationGraph }) => {
  return (
    <div className='background-white summary border-bottom'>
      <div className='container pv120-90'>
        <div class='header-subsection-print-button d-flex'>
          {summaryPdf && (
            <Link to={summaryPdf.publicURL} className='button button-default bold' download='true'>PDF</Link>
          )}
          {!summaryPdf && (
            <button type='button' onClick={() => window.print()} title="Select 'Save to PDF' as the Destination in the Print pop-up.">PDF</button>
          )}
        </div>
        <div>
          {locatorMap && (
            <div className='wfb-image-block'>
              <Image
                className='wfb-image'
                localFile={locatorMap}
              />
            </div>
          )}
          {flag && (
            <div className='wfb-image-block'>
              <Image
                className='wfb-image'
                localFile={flag}
              />
            </div>
          )}
          {map && (
            <div className='wfb-image-block'>
              <Image
                className='wfb-image'
                localFile={map}
              />
            </div>
          )}
          {populationGraph?.localFile && (
            <div className='wfb-image-block'>
              <Image
                className='wfb-image'
                image={populationGraph}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CountrySummaryImages;
