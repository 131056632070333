import './three-column-links.scss';
import React from 'react';
import { graphql } from 'gatsby';
import Link from '@shared/components/link/link';

const ThreeColumnLinks = ({ columnLinks }) => {
  const numberOfChunks = 3;

  const createColumns = () => {
    if (columnLinks.length < numberOfChunks && columnLinks.length > 0) {
      const chunkLen = 1;
      return [...Array(columnLinks.length)].map((val, index) => {
        return columnLinks.slice(index, index + chunkLen);
      });
    } else if (columnLinks.length > 0) {
      const chunkLen = Math.ceil(columnLinks.length / numberOfChunks);
      return [...Array(numberOfChunks)].map((val, index) => {
        return columnLinks.slice(index * chunkLen, (index + 1) * chunkLen);
      });
    } else {
      return [];
    }
  };

  const columns = createColumns();
  return (
    <section className="background-white three-col-links-wrapper">
      <div className="container pv120-90">
        <div className="row">
          {columns &&
            columns.map((col, i) => (
              <div className="col-md-4 border-left-db5" key={i}>
                <ul>
                  {col.map((link, index) => (
                    <li key={index}>
                      {link.preface && <label>{link.preface}</label>}
                      {link.link?.uri && (
                        <div className="h5-size">
                          <Link className="link-button bold" to={link.link.uri}>
                            {link.title}
                          </Link>
                        </div>
                      )}
                      {link.uri && (
                        <h5>
                          <Link className="link-button bold" to={link.uri}>
                            {link.title}
                          </Link>
                        </h5>
                      )}
                      {/* OMA Plugin Region Specific */}
                      {link.name && (
                        <div className="h5-size">
                          <Link className="link-button bold" to={"/countries/" + link.slug}>
                            {link.name}
                          </Link>
                        </div>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export const ThreeColumnLinksFragment = graphql`
  fragment ThreeColulmnLinksFields on wordpress__wp_CondorThreeColumnLinkBlock {
    innerBlocks {
      name
      ... on wordpress__wp_CondorColumnLinkBlock {
        attributes {
          preface
          title
          link {
            title
            uri
          }
        }
      }
    }
  }
`;

export default ThreeColumnLinks;
