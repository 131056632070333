import { useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

export const useCategories = () => {
  return useStaticQuery(graphql`
    query CategoriesName {
      allLaunchpadCategory(sort: {fields: position}) {
        nodes {
          name
          locations
          fieldLabels {
            name
            databaseId
          }
        }
      }
    }
  `).allLaunchpadCategory.nodes;
};

const generateCategory = (categories, fieldMap, location) => {
  return categories.reduce((result, category) => {
    if (!category.locations.find((catLocation) => catLocation === location))
      return result;

    const orderedCategory = category.fieldLabels.reduce((result, categoryField) => {
      const field = fieldMap[categoryField.databaseId];
      if (field) {
        result.push(field);
      }

      return result;
    }, []);

    if (orderedCategory.length > 0) {
      result.push({
        name: category.name,
        nodes: orderedCategory,
      });
    }
    return result;
  }, []);
}

export const useOrderFieldLabels = (fieldLabels, location = 'place') => {
  const categories = useCategories();

  return useMemo(() => {
    const fieldMap = fieldLabels.reduce((result, fieldLabel) => {
      result[fieldLabel.databaseId] = fieldLabel;
      return result;
    }, {});

    return generateCategory(categories, fieldMap, location);
  }, [fieldLabels]);
};

export const useOrderFields = (fields, location = 'place') => {
  const primaryFields = [undefined, null, "", "primary"];
  const categories = useCategories();

  return useMemo(() => {
    const secondaryFieldMap = fields.reduce((result, field) => {
      if (!primaryFields.includes(field.fieldLabel[0].toggleType)) {
        result[field.fieldLabel[0].databaseId] = field;
      }
      return result;
    }, {});

    const fieldMap = fields.reduce((result, field) => {
      if (primaryFields.includes(field.fieldLabel[0].toggleType)) {
        result[field.fieldLabel[0].databaseId] = field;
        result[field.fieldLabel[0].databaseId].secondary = secondaryFieldMap[field.fieldLabel[0].toggleWithId];
      }
      return result;
    }, {});

    return generateCategory(categories, fieldMap, location);
  }, [fields]);
};
