import React from 'react';
import PageBuilder, { getBlockComponent } from '@shared/components/page-builder/page-builder';
import HeroStacked from '@shared/components/hero-area/hero-stacked/hero-stacked';
import ArticleBody from '@shared/components/article/article-body/article-body';
import CoverGallery from '@src/components/index/cover-gallery';
import WorldMaps from '@src/components/index/world-maps';
import CountryIndex from '@src/components/index/country-index';
import DailyFact from '@src/components/daily-fact';
import CountrySearch from '@src/components/search/country-search';
import DefinitionsNotesIndex from '@src/components/index/definition-note-index';
import CountrySummaryIndex from '@src/components/index/country-summary-index';
import FlagIndex from '@src/components/index/flag-index';
import TravelFactIndex from '@src/components/index/travel-fact-index';
import CountryComparisonGuideIndex from '@src/components/index/country-comparison-guid-index';
import CountryProfileGuideIndex from '@src/components/index/country-profile-guide-index';
import WFBTeaser from '@src/components/teaser/wfb-teaser';
import ThreeColumnLinks from '@src/components/three-column-links/three-column-links';

const SiteComponent = ({ block, baseUri }) => {

  return <>{useBlockComponent(block.attributes, block.innerBlocks, baseUri)[(block.name)] ?? null}</>;
};

export const useBlockComponent = (block, innerBlocks, pageContext) => ({
  'condor/hero-stacked': (
    <HeroStacked {...block} textSnippet={block?.textSnippet || `Edition: ${pageContext?.buildTime}`}>
      {innerBlocks && (<PageBuilder blocks={innerBlocks} getSiteComponent={useBlockComponent} />)}
    </HeroStacked>
  ),
  'condor/search-input': {
    'countries': <CountrySearch {...block} />,
  }[block?.searchType],
  'condor/index': {
    'covers': <CoverGallery />,
    'worldMaps': <WorldMaps />,
    'countries': <CountryIndex />,
    'definitionsAndNotes': <DefinitionsNotesIndex />,
    'flags': <FlagIndex />,
    'travelFacts': <TravelFactIndex />,
    'countrySummaries': <CountrySummaryIndex />,
    'countryProfileGuide': <CountryProfileGuideIndex />,
    'countryComparisionGuide': <CountryComparisonGuideIndex />,
  }[block?.indexKey],
  'condor/daily-fact': <DailyFact />,
  'condor/three-column-link': <ThreeColumnLinks columnLinks={blockAttributes(innerBlocks)} />,
  'condor/teaser': {
    'fixed': (
      <WFBTeaser {...block}>
        {innerBlocks && (
          <PageBuilder blocks={innerBlocks} getSiteComponent={useBlockComponent} />
        )}
      </WFBTeaser >
    ),
    'fluid': getBlockComponent(block, innerBlocks, {}, useBlockComponent)['condor/teaser'],
    'headshot': getBlockComponent(block, innerBlocks, {}, useBlockComponent)['condor/teaser'],
  }[block?.imageType],
  'condor/article-body': (
    <ArticleBody {...block} padding>
      {innerBlocks && (<PageBuilder blocks={innerBlocks} getSiteComponent={useBlockComponent} />)}
    </ArticleBody>
  ),
});

export const blockAttributes = (blocks) => {
  return blocks?.map((block) => block.attributes)
};

export default SiteComponent;
