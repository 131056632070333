import '@shared/components/checkbox/checkbox.scss';
import React, { useMemo } from 'react';
import { SelectFilterMenu, SelectFilterButton } from '@shared/components/index/filter/select-filter-menu';

const SelectFilterLayout = ({ items, selection, title = 'Fields', onSelection, getItemValue }) => {
  const allValues = useMemo(() => {
    //We use the [].concat() syntax because items can have nested arrays, this always produces a flat array.
    return new Set([].concat(...items.map((item) => getItemValue(item))));
  }, [items]);

  const selectionValue = selection ?? getItemValue(items?.[0]);

  return (
    <SelectFilterMenu selection={selectionValue} label={title}>
      {[...allValues].map((value) => (
        <SelectFilterButton
          key={value}
          selected={selectionValue === value}
          onSelection={onSelection}
          item={value}
          label={'Field'}
        />
      ))}
    </SelectFilterMenu>
  );
};

export default SelectFilterLayout;
