import React from 'react';
import { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import Layout from '@shared/components/layout/layout';
import PageBuilder from '@shared/components/page-builder/page-builder';
import FadeInSection from '@shared/components/utils/fade-in-section';
import HeroSplash from '@shared/components/hero-area/hero-splash/hero-splash';
import Teaser from '@src/components/teaser/wfb-teaser';
import { WFB_TEMPLATE } from '@src/utils/constants';
import { useGlobalFooter } from '@shared/hooks/use-global-footer';
import { useFactsArchive } from '@src/hooks/use-daily-fact';

const DailyFactArchive = ({ data }) => {
  const [facts, setFacts] = useState(null);
  const cachedFacts = useFactsArchive();

  useEffect(() => {
    setFacts(cachedFacts);
  }, []);

  const parentPage = { title: 'The World Factbook', uri: '/' };

  return (
    <Layout 
      template={WFB_TEMPLATE}
      title='Daily Facts Archive - The World Factbook'
      headerMenu={data.header?.menuItems?.nodes} 
      footerMenu={useGlobalFooter()}
    >
      <FadeInSection hero={true}>
        <HeroSplash
          title={'Daily Facts Archive'}
          threadType='resources-threads'
          parentPage={parentPage}
        />
      </FadeInSection>
      {facts && facts.map((fact, index) => (
        <Teaser
          key={index}
          {...fact?.blocks?.[0]?.attributes}
          backgroundColor={index % 2 === 0 ? 'white' : 'black'}
          orientation={index % 2 === 0 ? 'left' : 'right'}
          labelComp={<div className={`label daily-fact__date pv15 ${index % 2 === 0 ? ' pl15' : ''}`}>{fact.visibleDate}</div>}
          title=''
          inset={true}
        >
          <h4>{fact.title}</h4>
          {fact?.blocks?.[0]?.innerBlocks && (
            <PageBuilder blocks={fact?.blocks?.[0]?.innerBlocks} />
          )}
        </Teaser >
      ))}
    </Layout>
  );
};

export default DailyFactArchive;

export const pageQuery = graphql`
  query {
    header: wordpressWpMenu(slug: {eq: "global-header"}) {
      ...Header
    }
  }
`;
