exports.components = {
  "component---src-pages-daily-facts-archive-js": () => import("./../../../src/pages/daily-facts-archive.js" /* webpackChunkName: "component---src-pages-daily-facts-archive-js" */),
  "component---src-pages-special-product-files-js": () => import("./../../../src/pages/special-product-files.js" /* webpackChunkName: "component---src-pages-special-product-files-js" */),
  "component---src-templates-appendix-js": () => import("./../../../src/templates/appendix.js" /* webpackChunkName: "component---src-templates-appendix-js" */),
  "component---src-templates-country-images-js": () => import("./../../../src/templates/country-images.js" /* webpackChunkName: "component---src-templates-country-images-js" */),
  "component---src-templates-country-js": () => import("./../../../src/templates/country.js" /* webpackChunkName: "component---src-templates-country-js" */),
  "component---src-templates-country-media-detail-js": () => import("./../../../src/templates/country-media-detail.js" /* webpackChunkName: "component---src-templates-country-media-detail-js" */),
  "component---src-templates-country-summary-js": () => import("./../../../src/templates/country-summary.js" /* webpackChunkName: "component---src-templates-country-summary-js" */),
  "component---src-templates-field-comparison-js": () => import("./../../../src/templates/field-comparison.js" /* webpackChunkName: "component---src-templates-field-comparison-js" */),
  "component---src-templates-field-js": () => import("./../../../src/templates/field.js" /* webpackChunkName: "component---src-templates-field-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-region-js": () => import("./../../../src/templates/region.js" /* webpackChunkName: "component---src-templates-region-js" */),
  "component---src-templates-travel-facts-template-js": () => import("./../../../src/templates/travel-facts-template.js" /* webpackChunkName: "component---src-templates-travel-facts-template-js" */)
}

