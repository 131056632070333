import './field-article.scss';
import React, { useMemo, useState, createContext, useContext } from 'react';
import NavArticleBody from '@shared/components/article/nav-article-body/nav-article-body';
import AudioPlayer from '@shared/components/audio-player/audio-player';
import Link from '@shared/components/link/link';
import Image from '@shared/components/image';
import FileIcon from '@shared/svgs/icons/file';
import { cleanForSlug } from '@shared/utils/clean-slug';
import LaunchpadContent from '@src/components/launchpad-content/launchpad-content';
import MediaLightboxContainer, { MediaButton } from '@src/components/media-lightbox/media-lightbox';

export const FieldCategoryContext = createContext(null);

const FieldCategoryArticle = ({ categories, useFieldLink, useRank, children = <CategoryBuilder /> }) => {
  const menu = useMemo(() => {
    return categories.map((category) => ({
      label: category.name,
      slug: cleanForSlug(category.name),
    }));
  }, [categories])
  return (
    <NavArticleBody menu={menu}>
      <FieldCategoryContext.Provider value={{ categories, useFieldLink, useRank }}>
        {children}
      </FieldCategoryContext.Provider>
    </NavArticleBody>
  );
};

export const CategoryBuilder = ({ renderCategoryCallback, FieldComp = FieldDisplay }) => {
  const { categories } = useContext(FieldCategoryContext) ?? {};

  if (renderCategoryCallback) {
    return categories?.map((category) => renderCategoryCallback?.(category));
  }
  else {
    return categories?.map((category) => <CategoryDisplay key={category.name} category={category} FieldComp={FieldComp} />);
  }
};

export const CategoryDisplay = ({
  className = 'free-form-content__content wysiwyg-wrapper wfb-nav-article',
  category,
  FieldComp = FieldDisplay
}) => {

  return (
    <div id={cleanForSlug(category.name)} className={className}>
      <h2>{category.name}</h2>
      {category.nodes?.map((field, index) => (
        <FieldComp key={index} {...field} />
      ))}
    </div>
  );
};

export const FieldDisplay = (totalData) => {
  const { useFieldLink = false, useRank = true } = useContext(FieldCategoryContext) ?? {};
  const [ isToggled, setToggled ] = useState(false);
  const { name, fieldLabel, media, subfields } = {...totalData};
  const { data } = isToggled ? {...totalData.secondary} : {...totalData};

  if (!data) return null;

  return (
    <div>
      {totalData.secondary && (
        <button type="button" title="Toggle Sort" className="toggleButton" onClick={() => setToggled(!isToggled)}>⇅</button>
      )}
      <h3 className='mt30'>
        {useFieldLink && (
          <Link to={`/field/${cleanForSlug(name)}`}>{name}</Link>
        )}
        {!useFieldLink && name}
      </h3>
      <p>
        <LaunchpadContent content={data} />
      </p>
      {useRank && fieldLabel?.[0]?.rank && (
        <RankLink
          uri={`/field/${cleanForSlug(name)}/country-comparison`}
          subfields={subfields}
          fieldLabeCount={fieldLabel[0].subfieldLabels?.length}
        />
      )}
      <MediaLightboxContainer images={media?.filter(item => item.type === 'image')} >
      {media?.map((mediaItem, index) => (
        <React.Fragment key={index}>
          {mediaItem.type === 'audio' && (
            <>
              <div className="card-exposed__text bold h5">
                {mediaItem.altText || ''}
                {mediaItem.altText ? ':' : ''}
              </div>
              <AudioPlayer audioUrl={mediaItem.localFile.publicURL} />
            </>
          )}
          {mediaItem.type === 'image' && (
            <>
              <div className="bold">{mediaItem.altText}:</div>
              <div className='remove-margins-h'>
                <div className='col-sm-12 wfb-field-thumbnail'>
                  <MediaButton className='wfb-media-card' index={index}>
                    <Image className="wfb-media-card-img" image={mediaItem} />
                  </MediaButton>
                </div>
              </div>
            </>
          )}
          {mediaItem.type === 'document' && (
            <Link to={mediaItem.localFile.publicURL} className="card-exposed__link pl0" download="true">
              <span className="card-exposed__text link-button bold h5">
                {mediaItem.caption || mediaItem.altText}
              </span>
              <FileIcon className="icon" />
            </Link>
          )}
        </React.Fragment>
      ))}
      </MediaLightboxContainer>
    </div>
  );
};

export const RankLink = ({ uri, subfields, fieldLabeCount}) => {
  const rankedSubfields = subfields?.filter((subfield) => subfield.ranking !== null);
  if (!rankedSubfields?.length)
    return null;

  const rankText = `comparison ranking${rankedSubfields.length > 1 ? 's' : ''}`;
  const fieldText = fieldLabeCount > 1 ?
    rankedSubfields.map((subfield) => `${subfield.label} ${subfield.ranking}`).join('; ') :
    rankedSubfields[0].ranking;

  return (
    <p>{rankText}: <Link to={uri}>{fieldText}</Link></p>
  )
};

export default FieldCategoryArticle;
