import React, { useState } from 'react';
import { IndexLayout, IndexContent } from '@shared/components/index/index-layout';
import FadeInSection from '@shared/components/utils/fade-in-section';
import { SCROLL_IN_OFFSET } from '@src/utils/constants';
import CategoryFilter from '@shared/components/index/filter/category-filter';
import FilterMenu, { ResultLabel } from '@shared/components/index/filter/filter-menu';
import { UnmanagedPaginationControl } from '@shared/components/index/pagination-controls/pagination-controls';
import Image from '@shared/components/image';
import CardTip from '@shared/components/card-tip/card-tip';
import WorldFactBookDark from '@shared/svgs/glyphs/wfb-dark';
import { useFilters } from '@shared/hooks/use-filters';
import MediaLightboxContainer, { MediaButton } from '@src/components/media-lightbox/media-lightbox';
import ResultLengthDropdown from '@src/components/result-length-dropdown';


const LightboxIndex = ({ images }) => {
  const [lengthValue, setLengthValue] = useState(12);
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters, applyFilters] = useFilters();

  const { categoryFilter } = filters;
  const results = applyFilters(images);

  return (
    <>
      <FadeInSection wait={SCROLL_IN_OFFSET.fade_in_offset + SCROLL_IN_OFFSET.hero_offset}>
        <IndexLayout>
          <FilterMenu sticky={false}>
            <CategoryFilter
              value={categoryFilter}
              onFilterChange={(value) => {
                setCurrentPage(1);
                setFilters({ categoryFilter: value });
              }}
              items={images}
              getItemValue={(item) => item.category}
            />
          </FilterMenu>
          <IndexContent>
            <div className='row'>
              <div className='col-lg-9'>
                <ResultLabel size={results.length} label='Photos' />
              </div>
              {results.length > 12 && (
                <ResultLengthDropdown 
                  length={results.length}
                  value={lengthValue}
                  onChange={(value) => {
                    setLengthValue(value);
                    setCurrentPage(1);
                  }} 
                />
              )}
            </div>
            <UnmanagedPaginationControl
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              renderCallback={(items) => (
                <MediaLightboxContainer images={results}>
                  {items.map((item, index) => (
                    <div key={index} className='col-lg-4 wfb-media-card-lightbox'>
                      <MediaButton className='wfb-media-card' index={index + (currentPage - 1) * lengthValue }>
                        <Image
                          className="wfb-media-card-img"
                          image={item}
                        />
                      </MediaButton>
                    </div>
                  ))}
                </MediaLightboxContainer>
              )}
              items={results}
              pageSize={lengthValue < 0 ? results.length + 1 : lengthValue}
            />
            <div className='col-lg-9'>
              <CardTip
                title='Usage'
                link={{ uri: '/about/copyright-and-contributors/', title: 'View Copyright Notice' }}
                icon={<WorldFactBookDark />}
              >
                <p>Factbook images and photos — obtained from a variety of sources — are in the public domain and are copyright free.</p>
              </CardTip>
            </div>
          </IndexContent>
        </IndexLayout>
      </FadeInSection>
    </>
  );
};

export default LightboxIndex;
