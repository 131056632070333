
export const cleanSEO = (seo, replaceMap) => {
  for (const key in seo) {
    if (typeof seo[key] !== 'string') continue;

    seo[key] = Object.keys(replaceMap).reduce((result, replaceKey) => {
      return result.replace(replaceKey, replaceMap[replaceKey]);
    }, seo[key]);
  }

  return seo;
};
