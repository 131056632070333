import React from 'react';
import { graphql } from 'gatsby';
import LaunchpadLayout from '@src/components/launchpad-layout';
import HeroSplash from '@shared/components/hero-area/hero-splash/hero-splash';
import { IndexLayout, IndexContent } from '@shared/components/index/index-layout';
import AZFilter from '@shared/components/index/filter/az-filter';
import FilterMenu, { ResultLabel } from '@shared/components/index/filter/filter-menu';
import LaunchpadContent from '@src/components/launchpad-content/launchpad-content';
import Link from '@shared/components/link/link';
import { cleanForSlug } from '@shared/utils/clean-slug';
import { useFilters } from '@shared/hooks/use-filters';
import { cleanSEO } from '@src/utils/clean-seo';
import Seo from '@shared/components/layout/seo';

const FieldTemplate = ({ data }) => {
  const { rep, fieldLabel, fields, header } = data;
  const parentPage = { title: 'Field Listing', uri: '/references/definitions-and-notes/' };
  const cleanedSEO = cleanSEO(rep.seo, {
    '$title': fieldLabel.name,
  });

  const [filters, setFilters, applyFilters] = useFilters();
  const { azFilter } = filters;
  const results = applyFilters(fields.nodes);

  return (
    <LaunchpadLayout
      metaData={cleanedSEO}
    >
      <HeroSplash
        title={fieldLabel.name}
        threadType='resources-threads'
        parentPage={parentPage}
      />
      <IndexLayout>
        <FilterMenu sticky={true}>
          <ResultLabel
            size={results.length}
            clearAction={() => setFilters({ azFilter: null })}
          />
          <AZFilter
            value={azFilter}
            onFilterChange={(value) => setFilters({ azFilter: value })}
            items={fields.nodes}
            getItemValue={(item) => item.placeName}
          />
        </FilterMenu>
        <IndexContent>
          <p><LaunchpadContent content={fieldLabel.definition} /></p>
          {results?.map((item, index) => (
            <div key={index} className='pb30'>
              <h3 className='mt10'>
                <Link to={(item.place.region == 'Oceans' ? '/oceans/'  : '/countries/')
                          + `${cleanForSlug(item.placeName)}/`}>{item.placeName}</Link>
              </h3>
              <LaunchpadContent content={item.formatted} />
            </div>
          ))}
        </IndexContent>
      </IndexLayout>
    </LaunchpadLayout>
  );
};

export const Head = ({ data, base }) => {
  const page = Object.values(data).find((item) => item !== null);
  return ( <Seo metaData={page.seo} base={base} title={`${data.fieldLabel.name} - The World Factbook`} /> );
}

export const fieldQuery = graphql`
  query($fieldLabelId: ID!) {
    fieldLabel: launchpadFieldLabel(databaseId: {eq: $fieldLabelId}) {
      name
      definition
    }
    fields: allLaunchpadField(
      filter: {fieldLabelId: {eq: $fieldLabelId}, formatted: {nin: ["", null]}, place: {redirectCode: {in: ["", null]}, name: {ne: null}}}
      sort: {fields: placeName, order: ASC}
    ) {
      nodes {
        placeName
        formatted
        place {
          region 
        }
      }
    }
    rep: wordpressWpField {
      seo {
        ...SEOFields
      }
    }
  }
`;

export default FieldTemplate;
