import React, { useMemo, useState } from 'react';
import { graphql } from 'gatsby';
import LaunchpadLayout from '@src/components/launchpad-layout';
import HeroSplash from '@shared/components/hero-area/hero-splash/hero-splash';
import { IndexLayout, IndexContent } from '@shared/components/index/index-layout';
import CategoryFilter from '@shared/components/index/filter/category-filter';
import FilterMenu, { ResultLabel } from '@shared/components/index/filter/filter-menu';
import Link from '@shared/components/link/link';
import { cleanForSlug } from '@shared/utils/clean-slug';
import ContentTable from '@shared/components/content-table/content-table';
import { useFilters } from '@shared/hooks/use-filters';
import SelectFilterLayout from '@src/components/index/filter/select-filter-layout';
import Seo from '@shared/components/layout/seo';

const FieldComparisonTemplate = ({ data }) => {
  const { fieldLabel, fields } = data;
  const parentPage = { title: 'Country Comparisons', uri: '/references/guide-to-country-comparisons/' };

  const rankedFieldLabels = useMemo(() => {
    return fieldLabel.subfieldLabels.filter((value) => value.rank);
  }, [fieldLabel]);

  const rankedSubFields = useMemo(() => {
    const groups = fields.nodes.reduce((result, field) => {
      rankedFieldLabels.forEach((fieldLabel) => {
        const { subfields, place } = field;
        const subfield = subfields.find((item) => item.subfieldLabelId === fieldLabel.id);

        if (!result[fieldLabel.id]) {
          result[fieldLabel.id] = [];
        }

        if (subfield?.ranking) {
          result[fieldLabel.id].push({
            ...place,
            subfield,
          });
        }
      })
      return result;
    }, {});

    Object.keys(groups).forEach((key) => {
      groups[key].sort((a, b) => a.subfield.ranking - b.subfield.ranking);
    })

    return groups;
  }, [fields, rankedFieldLabels]);

  const [subFieldIndex, setSubFieldIndex] = useState(0);
  const subfields = rankedSubFields[rankedFieldLabels[subFieldIndex].id];

  const [filters, setFilters, applyFilters] = useFilters();
  const { regionFilter } = filters;
  const results = applyFilters(subfields);

  const filename = fieldLabel.name + (rankedFieldLabels.length > 1 && rankedFieldLabels[subFieldIndex].name ? ' - ' + rankedFieldLabels[subFieldIndex].name : '')
                                   + (regionFilter?.selection ? ' -Regions[' + regionFilter?.selection + ']' : '');

  return (
    <LaunchpadLayout
      title={fieldLabel.name}
    >
      <HeroSplash
        title={fieldLabel.name}
        threadType='resources-threads'
        parentPage={parentPage}
      >
        {rankedFieldLabels[subFieldIndex].rankDescription}
      </HeroSplash>
      <IndexLayout>
        <FilterMenu>
          <ResultLabel
            size={results.length}
            clearAction={() => {
              setFilters({ regionFilter: null });
              setSubFieldIndex(0);
            }}
          />
          {rankedFieldLabels.length > 1 && (
            <SelectFilterLayout
              title='Rank Type'
              selection={rankedFieldLabels[subFieldIndex].name}
              onSelection={(value) => {
                const fieldLabelIndex = rankedFieldLabels.findIndex((label) => label.name === value);
                setSubFieldIndex(fieldLabelIndex);
              }}
              items={rankedFieldLabels}
              getItemValue={(item) => item.name}
            />
          )}
          <CategoryFilter
            title='Regions'
            value={regionFilter}
            onFilterChange={(value) => setFilters({ regionFilter: value })}
            items={subfields}
            getItemValue={(item) => item.region}
            multiSelect
          />
        </FilterMenu>
        <IndexContent>
          {results && (
            <div className="col-12">
              <div className="border pb30">
                <div className="pb30">
                  <ContentTable
                    headerLabels={['Rank', 'Country', rankedFieldLabels[subFieldIndex].suffix, 'Date of Information']}
                    tableContent={results}
                    RowComponent={ComparisonRow}
                    filename={filename}
                    downloadHeaderLabels={['name', 'slug', rankedFieldLabels[subFieldIndex].suffix || 'value', 'date_of_information', 'ranking', 'region']}
                    getDownloadContent={() => results.map((result) => {
                      const { name, region, subfield: { ranking, rankDisplay, infoDate } } = result;
                      return { name, slug: cleanForSlug(name), value: rankDisplay, date_of_information: infoDate, ranking, region };
                    })}
                  />
                </div>
              </div>
            </div>
          )}
        </IndexContent>
      </IndexLayout>
    </LaunchpadLayout>
  );
};

const ComparisonRow = ({ rowData }) => {
  const { name, subfield: { ranking, rankDisplay, infoDate } } = rowData;
  return (
    <>
      <td>{ranking}</td>
      <td><Link to={`/countries/${cleanForSlug(name)}/`} className='text-button'>{name}</Link></td>
      <td>{rankDisplay}</td>
      <td>{infoDate && `${infoDate} est.`}</td>
    </>
  )
};

export const Head = ({ data, base }) => {
  const page = Object.values(data).find((item) => item !== null);
  return ( <Seo metaData={page.seo} base={base} title={`${data.fieldLabel.name} Comparison - The World Factbook`} /> );
}

export const query = graphql`
  query($fieldLabelId: ID!) {
    fieldLabel: launchpadFieldLabel(databaseId: {eq: $fieldLabelId}) {
      name
      definition
      subfieldLabels {
        id
        rank
        rankOrder
        name
        suffix
        rankDescription
      }
    }
    fields: allLaunchpadField(
      filter: {fieldLabelId: {eq: $fieldLabelId}, place: {rank: {eq: true}}}
    ) {
      nodes {
        place {
          name
          region
        }
        subfields {
          subfieldLabelId
          rankDisplay
          value
          infoDate
          ranking
        }
      }
    }
  }
`;

export default FieldComparisonTemplate;
