import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { IndexLayout, IndexContent } from '@shared/components/index/index-layout';
import AZFilter from '@shared/components/index/filter/az-filter';
import FilterMenu, { ResultLabel } from '@shared/components/index/filter/filter-menu';
import { UnmanagedPaginationControl } from '@shared/components/index/pagination-controls/pagination-controls';
import Link from '@shared/components/link/link';
import { useFilters } from '@shared/hooks/use-filters';
import { cleanForSlug } from '@shared/utils/clean-slug';
import { useCountries } from '@src/hooks/use-countries';

const CountriesIndex = () => {
  const countries = useCountries();
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters, applyFilters] = useFilters();

  const { azFilter } = filters;
  const results = applyFilters(countries);

  return (
    <IndexLayout>
      <FilterMenu sticky={true}>
        <ResultLabel
          size={results.length}
          label='Countries'
          clearAction={() => {
            setCurrentPage(1);
            setFilters({ azFilter: null });
          }}
        />
        <AZFilter
          value={azFilter}
          onFilterChange={(value) => {
            setCurrentPage(1);
            setFilters({ azFilter: value });
          }}
          items={countries}
          getItemValue={(item) => item.name}
        />
      </FilterMenu>
      <IndexContent>
        <UnmanagedPaginationControl
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          renderCallback={(items) => (
            <>
              {items?.map((item, index) => (
                <div key={index} className='col-lg-9'>
                  <div className='border pb10'>
                    <h2 className='mt30'>
                      <Link className='inline-link' to={`/countries/${cleanForSlug(item.redirect?.name ?? item.name)}/`}>{item.name}</Link>
                    </h2>
                  </div>
                </div>
              ))}
            </>
          )}
          items={results}
          pageSize={12}
        />
      </IndexContent>
    </IndexLayout>
  );
};

export default CountriesIndex;
