import { useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

export const useDailyFacts = () => {
  return useStaticQuery(graphql`
    query DailyFactQuery {
      facts: allWordpressWpDailyFact {
        nodes {
          title
          visibleDate(formatString: "MMMM DD, YYYY")
          originalDate: visibleDate
          blocks {
            name
            ... on wordpress__wp_CondorDailyFactEditBlock {
              attributes {
                image {
                  ...MediaItemFields
                }
              }
              innerBlocks {
                name
                ... on wordpress__wp_CoreParagraphBlock {
                  ...CoreParagraphFields
                }
                ... on wordpress__wp_CoreListBlock {
                  originalContent
                }
                ... on wordpress__wp_CondorButtonBlock {
                  ...CondorButtonFields
                }
                ... on wordpress__wp_CondorSearchInputBlock {
                  ...SearchInputFields
                }
                ... on wordpress__wp_CoreHeadingBlock {
                  ...CoreHeadingFields
                }
              }
            }
          }
        }
      }
    }
  `).facts?.nodes;
}

export const useTodaysFact = () => {
  const facts = useDailyFacts();

  return useMemo(() => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return facts?.find((fact) => new Date(fact.visibleDate).getTime() === today.getTime());
  }, [facts]);
}

export const useFactsArchive = () => {
  const facts = useDailyFacts();

  return useMemo(() => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const prevDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 31);

    return facts?.filter((fact) => {
      const date = new Date(fact.visibleDate);
      return date.getTime() < today.getTime() && date.getTime() >= prevDate.getTime();
    })?.sort((a, b) => a.originalDate < b.originalDate);
  }, [facts]);
};
