import React from 'react';
import { graphql } from 'gatsby';
import LaunchpadLayout from '@src/components/launchpad-layout';
import HeroSplash from '@shared/components/hero-area/hero-splash/hero-splash';
import Image from '@shared/components/image';
import { cleanForSlug } from '@shared/utils/clean-slug';
import FieldCategoryArticle, { CategoryBuilder, CategoryDisplay } from '@src/components/field-article';
import CountrySummaryImages from '@src/components/country-summary-images/country-summary-images';
import { useOrderFields } from '@src/hooks/use-order-categories';
import Seo from '@shared/components/layout/seo';

const CountrySummaryTemplate = ({ data }) => {
  const { country, fields, populationField, header, } = data;
  const orderedFields = useOrderFields(fields.nodes, 'summary');
  const parentPage = { title: country.name, uri: `/countries/${cleanForSlug(country.name)}` };

  return (
    <LaunchpadLayout
      title={`${country.name} - The World Factbook`}
    >
      <HeroSplash
        title={'Country Summary'}
        threadType='resources-threads'
        parentPage={parentPage}
      />
      <CountrySummaryImages {...country} populationGraph={populationField?.media?.[0]} />
      <FieldCategoryArticle categories={orderedFields}>
        <CategoryBuilder renderCategoryCallback={(category) => (
          <CategoryDisplay className='' key={category.name} category={category} />
        )}
        />
        <span>
          <hr />
          <p>Page last updated: {country.updated}</p>
        </span>
        {country.qrCode?.childImageSharp && (
          <div>
            <Image className='wfb-image' localFile={country.qrCode} />
          </div>
        )}
      </FieldCategoryArticle>
    </LaunchpadLayout>
  );
};

export default CountrySummaryTemplate;

export const Head = ({ data, base }) => {
  const page = Object.values(data).find((item) => item !== null);
  return ( <Seo metaData={page.seo} base={base} title={`${data.country?.name} Summary - The World Factbook`} /> );
}

export const query = graphql`
  query($id: String!, $placeCode: String!) {
    country: launchpadPlace(id: {eq: $id}) {
      name
      flag {
        ...FileFields
      }
      map {
        ...FileFields
      }
      locatorMap {
        ...FileFields
      }
      qrCode {
        childImageSharp {
          gatsbyImageData(
            height: 600
            quality: 100
            formats: [AUTO]
          )
        }
      }
      summaryPdf {
        publicURL
      }
      updated: placeUpdated(formatString: "dddd, MMMM DD, YYYY")
    }
    populationField: launchpadField(fieldLabelId: {eq: "341"}, placeCode: {eq: $placeCode}) {
      media {
        ...AttachmentFields
      }
    }
    fields: allLaunchpadField(
      filter: {placeCode: {eq: $placeCode}, fieldLabel: {elemMatch: {locations: {eq: "summary"}}}}
    ) {
      nodes {
        name
        data: summary
        fieldLabel {
          databaseId
        }
      }
    }
  }
`;
