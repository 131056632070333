//adjust WFB template path for local development based on
//the variable included in the npm run develop command.
const WFB_TEMPLATE = {
  title: 'The World Factbook',
  title_short: 'WFB',
  path: '/',
  layout: 'Logo',
  logo: 'WFB',
};

const SCROLL_IN_OFFSET = {
  fade_in_offset: 300,
  hero_offset: 1000,
  header_offset: 0,
};

export {
  SCROLL_IN_OFFSET,
  WFB_TEMPLATE,
};
