import path from 'path';
import { graphql, useStaticQuery } from 'gatsby';
import { cleanForSlug } from '@shared/utils/clean-slug';

export const useCreateURI = () => {
  const allFiles = useStaticQuery(graphql`
    query WordpressReps {
      appendix: wordpressWpAppendix {
        uri
      }
      country: wordpressWpCountry {
        uri
      }
      ocean: wordpressWpOcean {
        uri
      }
      field: wordpressWpField {
        uri
      }
    }
  `);

  const generateURI = (type, name) => `${path.dirname(allFiles[type]?.uri ?? '')}/${name}/`.replace(/\/{2,}/g, '/');

  return generateURI;
};
