import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { IndexLayout, IndexContent } from '@shared/components/index/index-layout';
import CategoryFilter from '@shared/components/index/filter/category-filter';
import AZFilter from '@shared/components/index/filter/az-filter';
import FilterMenu, { ResultLabel } from '@shared/components/index/filter/filter-menu';
import { UnmanagedPaginationControl } from '@shared/components/index/pagination-controls/pagination-controls';
import { useFilters } from '@shared/hooks/use-filters';
import CardMedia from '@shared/components/index/media-card/media-card';
import './world-maps.scss';

const WorldMaps = () => {
  const items = useStaticQuery(graphql`
    query WorldMapQuery {
      allWordpressWpTtWorldMap(sort: {fields: title, order: ASC}) {
        nodes {
          title
          indexCategories {
            nodes {
              slug
              name
            }   
          }     
          featuredImage {
            node {
              ...MediaItemFields
              localFile {
                prettySize
                extension
                publicURL
              } 
            }   
          }     
          featuredPDF {
            node {
              localFile {
                prettySize
                extension
                publicURL
              }
            }
          }
        }
      }
    }
  `)?.allWordpressWpTtWorldMap?.nodes;

  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters, applyFilters] = useFilters();

  const { azFilter, categoryFilter } = filters;
  const results = applyFilters(items);

  return (
    <IndexLayout>
      <FilterMenu sticky={true} label='Maps'>
        <ResultLabel
          size={results.length}
          label='Maps'
          clearAction={() => {
            setCurrentPage(1);
            setFilters({ azFilter: null, categoryFilter: null });
          }}
        />
        <CategoryFilter
          value={categoryFilter}
          onFilterChange={(value) => {
            setCurrentPage(1);
            setFilters({ categoryFilter: value });
          }}
          items={azFilter?.selection ? azFilter.lambda(items, azFilter.selection) : items}
          getItemValue={(item) => item.indexCategories.nodes.map((item) => item.name)}
          multipleCategories={true}
        />
        <AZFilter
          value={azFilter}
          onFilterChange={(value) => {
              setCurrentPage(1);
              setFilters({ azFilter: value });
          }}
          items={categoryFilter?.selection ? categoryFilter.lambda(items, categoryFilter.selection) : items}
          getItemValue={(item) => item.title}
        />
      </FilterMenu>
      <IndexContent>
        <UnmanagedPaginationControl
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          renderCallback={(items) => (
            <>
              {items?.map((item, index) => (
                <CardMedia
                  key={index}
                  title={item.title}
                  image={item?.featuredImage?.node}
                  pdf={item?.featuredPDF?.node}
                  uri={item.uri}
                  layout={'thirds'}
                  download='all'
                />
              ))}
            </>
          )}
          items={results}
          pageSize={9}
        />
      </IndexContent>
    </IndexLayout>
  );
};

export default WorldMaps;
