import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { IndexLayout, IndexContent } from '@shared/components/index/index-layout';
import FilterMenu, { ResultLabel } from '@shared/components/index/filter/filter-menu';
import PaginationControl from '@shared/components/index/pagination-controls/pagination-controls';
import CardMedia from '@shared/components/index/media-card/media-card';

const CoverGallery = () => {
  const covers = useStaticQuery(graphql`
    query CoversQuery {
      allWordpressWpTtEdition(sort: {fields: title, order: DESC}) {
        nodes {
          title
          uri
          featuredImage {
            node {
              ...MediaItemFields
            }
          }
        }
      }
    }
  `)?.allWordpressWpTtEdition?.nodes;

  return (
    <IndexLayout>
      <FilterMenu sticky={true}>
        {/*<ResultLabel size={covers?.length} />*/}
      </FilterMenu>
      <IndexContent>
        <PaginationControl
          renderCallback={(items) => (
            <>
              {items?.map((item, index) => (
                <CardMedia
                  key={index}
                  title={item.title}
                  image={item?.featuredImage?.node}
                  uri={item.uri}
                  layout={'thirds'}
                />
              ))}
            </>
          )}
          items={covers}
          pageSize={12}
        />
      </IndexContent>
    </IndexLayout>
  );
};

export default CoverGallery;
