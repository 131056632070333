import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { IndexLayout, IndexContent } from '@shared/components/index/index-layout';
import AZFilter from '@shared/components/index/filter/az-filter';
import FilterMenu, { ResultLabel } from '@shared/components/index/filter/filter-menu';
import Link from '@shared/components/link/link';
import Image from '@shared/components/image';
import { UnmanagedPaginationControl } from '@shared/components/index/pagination-controls/pagination-controls';
import { cleanForSlug } from '@shared/utils/clean-slug';
import { useFilters } from '@shared/hooks/use-filters';
import EnlargeIcon from '@shared/svgs/icons/enlarge';

const FlagIndex = () => {
  const places = useStaticQuery(graphql`
    query FlagNotes {
      places: allLaunchpadPlace(
        sort: {fields: name}
        filter: {flag: {name: {ne: null}}}
      ) {
        nodes {
          name
          flag {
            ...FileFields
          }
        }
      }
    }
  `).places.nodes;

  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters, applyFilters] = useFilters();
  const { azFilter } = filters;
  const results = applyFilters(places);

  return (
    <IndexLayout>
        <FilterMenu sticky={true}>
          <ResultLabel
            size={results.length}
            clearAction={() => {
              setFilters({ azFilter: null });
              setCurrentPage(1);
            }}
          />
          <AZFilter
            value={azFilter}
            onFilterChange={(value) => {
              setFilters({ azFilter: value });
              setCurrentPage(1);
            }}
            items={places}
            getItemValue={(item) => item.name}
          />
        </FilterMenu>
        <IndexContent>
          <UnmanagedPaginationControl
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            renderCallback={(items) => (
              <>
                {items?.map((item, index) => (
                  <div key={index} className='col-lg-4'>
                    <Link className='wfb-media-card' to={`/countries/${cleanForSlug(item.name)}/flag`}>
                      <Image
                        className='wfb-media-card-img'
                        image={{ localFile: item.flag }}
                      />
                      <span className="image-title">{item.name}</span>
                      <EnlargeIcon className='icon enlarge-icon' />
                    </Link>
                  </div>
                ))}
              </>
            )}
            items={results}
            pageSize={9}
          />
        </IndexContent>
      </IndexLayout>
  );
};

export default FlagIndex;
